<template>
    <div class="suggest">
       <div class="content_box_">
            <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
            <div class="title_bg_text_">结婚禁忌提醒</div>
            <p class="text_" >
                <span class="overstriking_ text_red__">一、婚嫁择日的禁忌</span> <br>
                1、在挑选结婚吉日的时候不能选择3、7、9月，因为3月包含清明，7月包含盂兰，9月包含重公，这几个日子在中国都是属于“鬼节”，因此不适合结婚。<br>
                2、每个月的初三、初七、十三、十八、廿二、廿七是传说中三娘煞的日子，这几天三娘会专门破坏新人结婚，因此也不能选。<br>
                3、选择的结婚吉日不能和新郎新娘的父母生辰相重合。<br>
                4、选择的结婚吉日不能和新郎新娘的属相冲突
            </p>
            <p class="text_" >
                <span class="overstriking_ text_red__">二、订婚时的禁忌</span> <br>
                1、订婚的时候双方一定不能说不吉利的话，例如“再见”“顺走”等等，因为这代表着新郎新娘可能会有再婚的情况，十分不吉利。<br>
                2、订婚的时候不能将雨伞和扇子带进婚宴场地，因为其代表的是“分散”的寓意。<br>
                3、男方送聘礼的时候数目切忌不能是单数，结婚习俗中双数有着好事成双的含义，而单数是不吉利的象征。
            </p>
            <p class="text_" >
                <span class="overstriking_ text_red__">三、婚前准备的禁忌</span> <br>
                1、婚礼场地选择不能在法院、检察院、警察局、医院等附近，因为这些地方是非多不吉祥。<br>
                2、婚礼场地的大门不能朝北和朝西开，因为背阳阴气非常重。<br>
                3、在婚礼前一天，新郎不可一个人单独睡新的婚床，这样有婚后独孤的寓意，因此要选择一名男童晚上陪同。
            </p>
            <p class="text_" >
                <span class="overstriking_ text_red__">四、结婚当天的禁忌</span> <br>
                1、男方去接亲的时候，姑姑和姨不能随同。因此姑谐音“孤”，姨谐音“疑”，寓意不吉利。<br>
                2、新娘结婚当天的衣服不能是带有口袋的，因为这会带走娘子的财运。<br>
                3、新娘在接亲离开娘家的时候不能不哭，哭的越厉害以后才会越来越有钱。<br>
                4、结婚当天无论新娘新郎有多累都不能躺下，不然会有常年生病的寓意。<br>
                5、新娘进男方家门的时候对于门槛一定是要跨过去而不是踩上去。<br>
                6、新郎在婚礼当天送去的喜饼都是给宾客的，新郎新娘是不能吃的，自己吃掉喜气是不吉利的。
            </p>
            <p class="text_" >
                以上就是结婚禁忌讲究的全部内容了，不同的地
                方虽然会有一些额外的禁忌，但是大体上都是一
                样的。相信只要你做好万全的婚前准备，那么一
                定能够举办一场不留遗憾的婚礼的!
            </p>
        </div>
        <div class="content_box_">
            <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
            <div class="title_bg_text_">你的财运指数（赠）</div>
            <p class="text_" v-html="data_list.money_status"></p>
            <p class="text_" style="margin-top:0"></p>
        </div>
    </div>
</template>

<script>
import resultData from "../../../mixin/nameMatching/result_content/resultData"
export default {
     mixins:[resultData],
}
</script>

<style lang="scss" scoped>
    .suggest{
        .content_box_{
            position: relative;
            width: 7.18rem;
            // height: 8.59rem;
            margin: 0 auto;
            background: #fff;
            border-radius: .30rem;
            box-shadow: 0 0 5px #e8c7d6;
            padding-top: .24rem;
            padding-bottom: .6rem;
            margin-bottom: .47rem;
            margin-top: 0.1rem;
            .flower_img_{
                position: absolute;
                width: 7.18rem;
                top: 0;
            }
            .title_bg_text_{
                width: 4.47rem;
                height: .73rem;
                margin: 0 auto;
                text-align: center;
                color: #C4393E;
                font-size: .38rem;
                font-weight: bold;
                line-height: .73rem;
                background: url('https://cdn.zhiyileiju.cn/WechatIMG544.jpeg') no-repeat;
                background-size: 100% 100%;
            }
            .name_box_{
                position: relative;
                width: 6.27rem;
                height: 1.41rem;
                margin: .55rem auto;
                .grade_box_{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 1.4rem;
                    height: 1.4rem;
                    font-size: .72rem;
                    font-weight: bold;
                    color: #FFA3B2;
                    text-align: center;
                    line-height: 1.4rem;
                    border-radius: 1.4rem;
                    background: #fff;
                }
                .name_box_common{
                    width: 50%;
                    height: 1.41rem;
                    .name_text{
                        font-size: .36rem;
                        font-weight: bold;
                        color: #3D659A;
                        padding-left: .28rem;
                        margin-top: .35rem;
                        p{
                            // font-size: .18rem;
                            font-size: .23rem;
                            font-weight: 400;
                            // margin-top: .18rem;
                        }
                    }
                }
                .name_left_box{
                    float: left;
                    border-top-left-radius: .1rem;
                    border-bottom-left-radius: .1rem;
                    background: #C7DFFF;
                }
                .name_right_box{
                    float: right;
                    border-top-right-radius: .1rem;
                    border-bottom-right-radius: .1rem;
                    background: #FECCDD;
                    .name_text{
                        text-align: right;
                        padding-right: .28rem;
                        color: #943858;
                    }
                }
            }
            .text_{
                padding: 0 .5rem;
                font-size: .28rem;
                color: #525252;
                line-height: .48rem;margin-top: .35rem;
            }
            .text_:last-child{
                margin-top: .37rem;
                color: #67386F;
            }
            .overstriking_{
                font-weight: bold;
            }
            .score_box_{
                display: inline-block;
                .star_box_{
                    display: inline-block;
                    width: .34rem;
                    margin-right: .08rem;
                }
            }
            .text_red__{
                color: #C4393E;
            }
            
            .title_text__{
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #C4393E;
                text-align: center;
                margin-top: .36rem;
                margin-bottom: .28rem;
            }
            
            .user_name_text_{
                padding-left: .37rem;
                font-size: .32rem;
                font-weight: bold;
                color: #525252;
                 margin-top: .86rem;
            }
            .user_name_text_:last-child{
                margin-top: 0;
            }
            .img_geomantic_box{
                position: relative;
                height: 2.6rem;
                margin-top: .6rem;
                .div_text_box{
                    width: 1.2rem;
                    // display: inline-block;
                    position: absolute;
                    height: .49rem;
                    font-size: .28rem;
                    color: #525252;
                    line-height: .49rem;
                }
                    .diamond_box{
                        display: inline-block;
                        width: .49rem;
                        height: .49rem;
                        line-height: .49rem;
                        text-align: center;
                        color: #fff;
                        background: #D74A50;
                    }
                .big_box_{
                    position: absolute;
                    width: 2rem;
                    height: .88rem;
                    font-size: .28rem;
                    color: #525252;
                    line-height: .88rem;
                    box-sizing: border-box;
                    border-left: .05rem solid #D74A50;
                    padding-left: .59rem;
                    .across_{
                        position: absolute;
                        top: 50%;
                        left: -.001rem;
                        transform: translate(0,-50%);
                        width: .44rem;
                        height: .05rem;
                        background: #D74A50;

                    }
                }
                .center_box__{
                    position: absolute;
                    left: 1.8rem;
                }
                .right_box__{
                    position: absolute;
                    right: 3.3rem;
                    .img_box__{
                        position: absolute;
                        left: 1.45rem;
                        top: .4rem;
                        width: .35rem;
                    }
                    
                }
            }
            .gossip_bg_box_{
                width: 7.18rem;
                height: 1.52rem;
                background: url('https://cdn.zhiyileiju.cn/WechatIMG547.jpeg') no-repeat;
                background-size: 100% 100%;
                margin-top: .52rem;
                margin-bottom: .46rem;
                    text-align: center;
                .name_text__{
                    color: #525252;
                    font-size: .36rem;
                    margin-top: .84rem;
                    line-height: .35rem;
                    width: 1.7rem;
                    .designation__{
                        display: inline-block;
                        color: #9C9C9C;
                        font-size: .23rem;
                        // transform: scale(1.4);
                    }
                }
                .man_name_{
                    float: left;
                    margin-left: .4rem;
                }
                .girl_name_{
                    float: right;
                     margin-right: .4rem;
                }
            }
            
        }
    }
</style>